<template>
  <div :class="baseClass + '-mobile-type-item-page'">
    <div :class="baseClass + '-mobile-type-item-page-title'">
      {{ currentType }}
    </div>
    <div :class="baseClass + '-mobile-type-item-page-content'">
      <Game
        v-for="item of $store.state.gameTypeHash[currentType]"
        :item="item"
        :key="item.id"
        type="mobile-item"
      />
    </div>
    <Tips />
  </div>
</template>
<script>
import Game from "@/components/GameItem.vue";
import Tips from "@/components/Tips.vue";
export default {
  components: { Game, Tips },
  data() {
    return {
      currentType: "",
    };
  },
  mounted() {
    this.currentType = this.$route.params.id;
  },
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-type-item-page {
  padding: 20px 15px;
  &-title {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #ffffff;
    padding: 10px 0;
    margin-bottom: 13px;
    background: linear-gradient(180deg, #fed03b 0%, #ff738d 100%);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #006cf3;
    padding: 5px;
    border-radius: 10px;
  }
}
</style>
